import Vue from 'vue';
import router from './router';

import VueAdsense from 'vue-google-adsense/dist/Adsense.min';
import VueAnalytics from 'vue-analytics';
import VueHead from 'vue-head';
import VueIdle from 'idle-vue';
import VueScript2 from 'vue-script2';
import VueVirtualScroller from 'vue-virtual-scroller';
import VueMoment from 'vue-moment';

// ---------------
// Env Variables
// ---------------

// Is env production?
const isProd = process.env.NODE_ENV === 'production';

// Is user admin [analytics prevention for admins]
let isNotAdmin = true;
if (localStorage.getItem('isAdmin') === 'true') {
  isNotAdmin = false;
}

// Is user admin [analytics prevention for admins]
declare global {
  interface Window { __PRERENDER_INJECTED: any; }
}

let isNotPrerender = true;
/* tslint:disable-next-line */
if (typeof window.__PRERENDER_INJECTED !== 'undefined') {
  isNotPrerender = false;
}

// ---------------
// Config
// ---------------

const VueAnalyticsSettings = {
  id: 'UA-116364147-1',
  router,
  debug: {
    // enabled: !isProd, // default value
    enabled: false, // default value
    track: !isProd, // default value
    sendHitTask: isProd && isNotAdmin && isNotPrerender, // True to send
  },
};

// -- Vue Idle
const eventsHub = new Vue();
const VueIdleSettings = {
  eventEmitter: eventsHub,
  idleTime: 180000,
};

Vue.use(VueHead, {
  separator: '',
  complement: '',
});

// ---------------
// Instantiate
// ---------------

Vue.use(VueAdsense);
Vue.use(VueAnalytics, VueAnalyticsSettings);
Vue.use(VueHead);
Vue.use(VueIdle, VueIdleSettings);
Vue.use(VueScript2);
Vue.use(VueVirtualScroller);
Vue.use(VueMoment);

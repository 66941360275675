import Vue from 'vue';
import Router from 'vue-router';
// import Home from '@/components/App1.vue';
import { createProvider } from './vue-apollo.js';
import gql from 'graphql-tag';
const apolloProvider = createProvider();
Vue.use(Router);

const SlsLoadout = () => import('@/tools/slaythespire/components/loadout.vue');
// example
// {
//   path: '/about',
//   name: 'about',
//   // route level code-splitting
//   // this generates a separate chunk (about.[hash].js) for this route
//   // which is lazy-loaded when the route is visited.
//   component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
// },
const roles = [
  'user',
  'moderator',
  'admin',
  'superadmin',
];

async function checkAuth(minRole: string) {
  try {
    const res = await apolloProvider.defaultClient.query({
      query: gql`query{
        me {
          role
        }
      }`,
    });
    const myRole = roles.indexOf(res.data.me.role);
    const minRoleRequired = roles.indexOf(minRole);
    console.log(myRole);
    console.log(minRoleRequired);
    console.log(myRole >= minRoleRequired);
    return myRole >= minRoleRequired;
  } catch (error) {
    console.log('Unauthorised');
  }
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '',
      // name: '',
      component: () => import('@/components/App1.vue'),
      children: [
        {
          path: '/',
          name: 'home',
          component: () => import('@/components/AppHome.vue'),
        },
        {
          path: 'blog/',
          name: 'blog',
          component: () => import('@/views/Blog.vue'),
        },
      ],
    },

    // ================ //
    // Ann Achronist
    // ================ //
    // {
    //   path: '/games/annachronist',
    //   component: () => import('@/games/annachronist/annachronist.vue'),
    //   // name: 'ai-app',
    //   children: [
    //     {
    //       path: '',
    //       name: 'game-aa-home',
    //       component: () =>
    //         import(/* webpackChunkName: "about" */ '@/games/annachronist/templates/home.vue'),
    //     },
    //   ],
    // },

    // ================ //
    // Slay The Spire
    // ================ //

    {
      path: '/tools/slaythespire',
      component: () => import('@/tools/slaythespire/App.vue'),
      children: [
        {
          path: '/',
          name: 'sls-home',
          components: {
            default: () => import('@/tools/slaythespire/templates/home.vue'),
            hero: () => import('@/tools/slaythespire/components/hero.vue'),
          },
        },
        {
          path: 'devlog/',
          name: 'sls-devlog',
          component: () => import('@/tools/slaythespire/templates/devlog.vue'),
        },
        {
          path: 'login/',
          name: 'sls-login',
          component: () => import('@/templates/loginSignUp.vue'),
        },
        {
          path: 'signup/',
          name: 'sls-signup',
          component: () => import('@/templates/loginSignUp.vue'),
        },
        {
          path: 'mods/',
          name: 'sls-mods',
          component: () => import('@/tools/slaythespire/templates/mods.vue'),
        },
        {
          path: 'users/profile/:username',
          name: 'sls-user-profile',
          component: () => import('@/tools/slaythespire/components/user/profile.vue'),
        },


        {
          path: 'runs/view/:id',
          name: 'sls-rundetail',
          component: () => import('@/tools/slaythespire/components/runs/RunDetail.vue'),
        },
        {
          path: 'tierlist/cards/:character/',
          name: 'sls-tierlist-card',
          component: () => import('@/tools/slaythespire/templates/tierlistcards.vue'),
        },
        {
          path: 'tierlist/relics/:character/',
          name: 'sls-tierlist-relic',
          component: () => import('@/tools/slaythespire/templates/tierlistrelics.vue'),
          // children: [
        },
        // Tierlist redirects
        // redirect to ironclad if blank
        {
          name: 'sls-tierlist-cards-redirect',
          path: 'tierlist/cards/',
          redirect: {
            name: 'sls-tierlist-card',
            params: { character: 'ironclad' },
          },
        },
        {
          name: 'sls-tierlist-relics-redirect',
          path: 'tierlist/relics/',
          redirect: {
            name: 'sls-tierlist-relic',
            params: { character: 'ironclad' },
          },
        },
        // Old Card Tier List URL Redirects
        // redirect to new path from old path cardtierlist
        {
          path: 'tierlist/:character/',
          redirect: { name: 'sls-tierlist-card' },
        }, // Redirect old path to new
        // redirect to ironclad if blank, old path
        {
          path: 'tierlist/',
          redirect: {
            name: 'sls-tierlist-card',
            params: { character: 'ironclad' },
          },
        },

        {
          path: 'jsoncardcompiler/',
          name: 'sls-cardsatacompiler',
          component: () => import('@/tools/slaythespire/components/carddatacompiler.vue'),
        },
        {
          path: 'upload/',
          name: 'sls-uploadgames',
          // components: { default: User, sidebar: Sidebar },
          component: () =>
            import('@/tools/slaythespire/templates/uploader.vue'),
        },

        // SLS - ADMIN
        {
          path: 'admin/',
          component: () => import('@/tools/slaythespire/components/admin/admin.vue'),
          async beforeEnter(to, from, next) {
            try {
              if (await checkAuth('admin')) {
                next();
              } else {
                // throw to redirect
                throw new Error();
              }
            } catch (e) {
              next({
                name: 'sls-login', // back to safety route //
                query: { redirectFrom: to.fullPath },
              });
            }
          },
          children: [
            {
              path: '',
              name: 'sls-admin-home',
              component: () => import('@/tools/slaythespire/components/admin/adminhome.vue'),
            },
            {
              path: 'googleapi/',
              name: 'sls-admin-googleapi',
              component: () => import('@/tools/slaythespire/components/admin/googleSheetsOAuth2Code.vue'),
            },
            {
              path: 'data/',
              name: 'sls-admin-data',
              component: () => import('@/tools/slaythespire/components/admin/data.vue'),
            },
            {
              path: 'nobpopup/',
              name: 'sls-admin-nob-popup',
              component: () => import('@/tools/slaythespire/components/admin/adminNOBPopup.vue'),
            },
            {
              path: 'questionpopup/',
              name: 'sls-admin-question-popup',
              component: () => import('@/tools/slaythespire/components/admin/adminQuestionPopup.vue'),
            },
            {
              path: 'sitealert/',
              name: 'sls-admin-site-alert',
              component: () => import('@/tools/slaythespire/components/admin/adminSiteAlert.vue'),
            },
          ],
        },

        {
          path: ':type/:name/',
          name: 'sls-itemdetail',
          component: () =>
            import('@/tools/slaythespire/templates/itemdetailpage.vue'),
        },

        // SLS - 404 page [always last]
        {
          path: '*',
          name: 'sls-404',
          component: () => import('@/tools/slaythespire/components/404.vue'),
        },
      ],
    },

    // Both below use loadout component
    {
      path: '/tools/slaythespire/newgame/',
      name: 'sls-newgame',
      component: SlsLoadout,
    },
    {
      path: '/tools/slaythespire/loadout/',
      name: 'sls-loadout',
      component: SlsLoadout,
    },

    // ================ //
    // League
    // ================ //
    // {
    //   path: '/tools/teamfighttactics/',
    //   component: () => import('@/tools/league/app.vue'),
    //   children: [
    //     {
    //       path: '',
    //       name: 'tft-home',
    //       components: {
    //         default: () => import('@/tools/league/templates/home.vue'),
    //       },
    //     },
    //     {
    //       path: 'tierlist/champion/',
    //       name: 'tft-tierlist-champion',
    //       components: {
    //         default: () => import('@/tools/league/templates/tierlistchampion.vue'),
    //       },
    //     },
    //     {
    //       path: 'cheatsheet/items/',
    //       name: 'tft-cheatsheet-items',
    //       components: {
    //         default: () => import('@/tools/league/templates/tierlistitems.vue'),
    //       },
    //     },
    //     {
    //       path: 'feedback/',
    //       name: 'tft-feedback',
    //       components: {
    //         default: () => import('@/tools/league/templates/feedback.vue'),
    //       },
    //     },
    //   ],
    // },

    // ================ //
    // Redirect links
    // ================ //

    // In game redirects - do not edit path, only redirect!
    // Ann Achronist - in game 'more games' link redirect
    { path: '/ingamelink/annachronist/', redirect: '/' },

    // ================ //
    // 404 Page [always last on the router]
    // ================ //
    // {
    //   path: '/login/',
    //   component: () => import('@/components/login.vue'),
    //   name: 'login',
    // },
    {
      path: '*',
      component: () => import('@/templates/404.vue'),
      name: '404',
    },
  ],
});

// import Vue from 'vue';
// import VueRouter from 'vue-router';
// // import HelloWorld from './../components/HelloWorld';

// const MainApp = () => import('./../components/App1');
// const MainHome = () => import('./../components/AppHome');
// const MainBlog = () => import('./../templates/blog');
// const Main404 = () => import('./../templates/404');

// const AiPrivacyPolicy = () => import('./../games/ai/privacypolicy');

// // -----
// // Tools
// // -----

// // Slay The Spire
// const slsApp = () => import('./../tools/slaythespire/App');

// // const SlsCardDetail = () => import('./../tools/slaythespire/templates/carddetail');
// // const SlsRelicDetail = () => import('./../tools/slaythespire/templates/relicdetail');

// const SlsHero = () => import('./../tools/slaythespire/components/hero');
// // const SlsBrowser = () => import('./../tools/slaythespire/components/browse');
// // const SlsCardModal = () => import('./../tools/slaythespire/components/cardmodal');
// // const SlsCardDetailPage = () => import('./../tools/slaythespire/templates/carddetail');

// const SlsAdmin = () => import('./../tools/slaythespire/components/admin');

// const Sls404 = () => import('./../tools/slaythespire/components/404');

// // Artificial Indifference
// const AiApp = () => import('./../games/ai/aiapp.vue');
// const AiHome = () => import('./../games/ai/home');
// const AiGame = () => import('./../games/ai/game');

// const AiAdmin = () => import('./../games/ai/admin/dashboard.vue');
// // import AiPrivacyPolicy from '@/static/ai/AIPrivacyPolicy.pdf';

// // const slsHome = resolve => require(['./../tools/slaythespire/components/home.vue'], resolve);

// const routes = [
//   {
//     path: '/',
//     // name: 'home',
//     component: MainApp,
//     children: [
//       {
//         path: '',
//         name: 'home',
//         component: MainHome,
//       },
//       {
//         path: '/blog',
//         name: 'blog',
//         component: MainBlog,
//       },
//     ],
//   },
//   {
//     path: '/games/artificialindifference/privacypolicy',
//     component: AiPrivacyPolicy,
//   },

//   // Tools
//   // { path: '/tools/', component: AiPrivacyPolicy },

//   // // Artificial Indifference
//   // {
//   //   path: '/games/ai',
//   //   component: AiApp,
//   //   // name: 'ai-app',
//   //   children: [
//   //     {
//   //       path: '',
//   //       name: 'ai-home',
//   //       component: AiHome,
//   //     },
//   //     {
//   //       path: 'game',
//   //       name: 'ai-game',
//   //       component: AiGame,
//   //     },
//   //     {
//   //       path: 'admin',
//   //       name: 'ai-admin',
//   //       component: AiAdmin,
//   //     },
//   //   ],
//   // },

//   // Slay The Spire
//   {
//     path: '/tools/slaythespire',
//     // name: 'sls-home',
//     component: slsApp,
//     children: [
//       {
//         path: '',
//         name: 'sls-home',
//         components: {
//           default: slsHome,
//           hero: SlsHero,
//         },
//       },
//       {
//         path: 'devlog',
//         name: 'sls-devlog',
//         component: SlsDevlog,
//       },
//       {
//         path: 'tierlist/cards/:character',
//         name: 'sls-tierlist-card',
//         component: SlsCardTierlist,
//       },
//       {
//         path: 'tierlist/relics/:character',
//         name: 'sls-tierlist-relic',
//         component: SlsRelicTierlist,
//       },

//       // redirect to ironclad if blank
//       { name: 'sls-tierlist-cards-redirect', path: 'tierlist/cards/', redirect: { name: 'sls-tierlist-card', params: { character: 'ironclad' } } },
//       { name: 'sls-tierlist-relics-redirect', path: 'tierlist/relics/', redirect: { name: 'sls-tierlist-relic', params: { character: 'ironclad' } } },
//       // Old Card Tier List URL Redirects
//       // redirect to new path from old path cardtierlist
//       { path: 'tierlist/:character', redirect: { name: 'sls-tierlist-card' } }, // Redirect old path to new
//       // redirect to ironclad if blank, old path
//       { path: 'tierlist/', redirect: { name: 'sls-tierlist-card', params: { character: 'ironclad' } } },

//       // {
//       //   path: 'cards/:name',
//       //   name: 'sls-carddetail',
//       //   component: SlsCardDetail,
//       // },
//       // {
//       //   path: 'relics/:name',
//       //   name: 'sls-relicdetail',
//       //   component: SlsRelicDetail,
//       // },
//       {
//         path: 'jsoncardcompiler',
//         name: 'sls-cardsatacompiler',
//         component: SlsCardDataCompiler,
//       },
//       {
//         path: ':type/:name',
//         name: 'sls-itemdetail',
//         component: SlsItemDetail,
//       },

//     ],
//   },
//   {
//     path: '/tools/slaythespire/newgame',
//     name: 'sls-newgame',
//     component: SlsLoadout,
//   },
//   {
//     path: '/tools/slaythespire/loadout',
//     name: 'sls-loadout',
//     component: SlsLoadout,
//   },

//   {
//     path: '/tools/slaythespire/admin',
//     name: 'sls-admin',
//     component: SlsAdmin,
//   },
//   {
//     path: '/tools/slaythespire/*',
//     component: Sls404,
//     name: 'sls-404',
//   },
//   {
//     path: '*',
//     component: Main404,
//     name: '404',
//   },

// ];

// // This makes all the magic happen and Vue recognizes the router-view and router-link
// Vue.use(VueRouter);

// export default new VueRouter({
//   mode: 'history',
//   // base: base,
//   scrollBehavior(to, from, savedPosition) {
//     if (savedPosition) {
//       return savedPosition;
//     }
//     return { x: 0, y: 0 };
//   },
//   routes,
//   // short for routes: routes
// });

// // //Router
// // var base = window.location.host === 'localhost' ? '/pg/' : '/';
// // export default new VueRouter({
// //     mode: 'history',
// //     base: base,
// //     scrollBehavior (to, from, savedPosition) {
// //         if (savedPosition) {
// //             return savedPosition
// //         } else {
// //             return { x: 0, y: 0 }
// //         }
// //     },
// //     routes // short for routes: routes
// // });

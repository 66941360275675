import Vue from 'vue';
import './plugins';

import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';

// CSS
import './main.css';

import { createProvider } from './vue-apollo.js';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  apolloProvider: createProvider(),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}).$mount('#app');

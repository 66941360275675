<template>
  <div id="app">
    <!-- <img src="./assets/logo.png"> -->
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang="scss">
/* @import "./../scss/utilities/index.scss"; */
// @import "tailwindcss/base.css";

// body {
//   @apply m-0;
// }

// @import "tailwindcss/components.css";

// Custom Classes
// @import "./css/global.css";

// img {
//   width: 100%;
//   // display: block;
// }

// Custom Classes End

// @import "tailwindcss/utilities.css";

body {
  content-visability: auto;
}

// @import "tailwindcss/base";

// @import "tailwindcss/components";

// @import "tailwindcss/utilities";


#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  /* color: #2c3e50; */
  /* margin-top: 60px; */
}





@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}

// @include for-tablet-portrait-up {
//   font-size: 27px;
// }


</style>
